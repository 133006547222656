import { Routes } from '@angular/router';
import { AdminComponent } from './components/admin/admin.component';

export const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
  },
  { path: '**', redirectTo: '' },
];
