import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SessionResponse} from '../models/session-response';
import {environment} from '../../environments/environment';
import {map} from 'rxjs';
import {AuthResponse} from '../models/auth-response';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(public http: HttpClient) { }

  /* istanbul ignore next */
  getOAuthToken(payload: SessionResponse){
    return this.http.post(`${environment.dashboardURL}/geotab/auth`, payload).pipe(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map((res: any) => {
        const authResponse: AuthResponse = {
          authenticationResult : {
            accessToken: res.AuthenticationResult.AccessToken,
            idToken: res.AuthenticationResult.IdToken,
            refreshToken: res.AuthenticationResult.RefreshToken,
            expiresIn: res.AuthenticationResult.ExpiresIn,
            tokenType: res.AuthenticationResult.TokenType
          },
          tenant: res.tenant
        }
        return authResponse;
    }));
  }
}
