<div>
  <table class="m-table" aria-label="Highlights" style="width: calc(100% - 4rem)">
    <thead>
      <tr>
        <th>
          <span class="table-header"> {{"SMOKE_EVENT.dateTime" | translate}} </span>
        </th>
        <th>
          <span class="table-header">{{"SMOKE_EVENT.licencePlate" | translate}} </span>
        </th>
        <th>
          <span class="table-header">{{"SMOKE_EVENT.incidentId" | translate}} </span>
        </th>
        <th>
          <span class="table-header">{{"SMOKE_EVENT.reports" | translate}}</span>
        </th>
      </tr>
    </thead>
    <tbody>
      @for (incident of smokeEvents?.incidents; track incident.messageId) {
      <tr>
        <td><span> {{ incident.timestamp | dateConversion}}</span></td>
        <td><span> {{ incident.licencePlate}}</span></td>
        <td><span><a (click)="openDashboard(incident)">
              <i class="a-icon ui-ic-inline-externallink"></i>{{ incident.messageId}}</a></span></td>
        <td><span><a (click)="openReport(incident.messageId)"><i
                class="a-icon ui-ic-inline-externallink"></i>{{'SMOKE_EVENT.pdfReport' | translate}}</a> </span></td>
      </tr>
      }
    </tbody>
  </table>
</div>
