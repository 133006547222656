import {Component, OnInit} from '@angular/core';
import {TranslatePipe} from '@ngx-translate/core';
import {DateConversionPipe} from '../../pipes/date-conversion.pipe';
import {IncidentsService} from '../../services/incidents.service';
import {SessionResponse} from '../../models/session-response';
import {AuthenticationService} from '../../auth/authentication.service';

@Component({
  selector: 'app-smoke-incident',
  templateUrl: './smoke-incident.component.html',
  standalone: true,
  imports: [
    TranslatePipe,
    DateConversionPipe
  ],
  styleUrl: './smoke-incident.component.scss'
})
export class SmokeIncidentComponent implements OnInit{

  smokeEvents;
  sessionResponse: SessionResponse;
  
  constructor(private readonly incidentService: IncidentsService, private authenticationService: AuthenticationService) {}

  ngOnInit(): void {
    const res = localStorage.getItem('sTokens_vehicle_data_for_ridecare');
    this.sessionResponse = JSON.parse(res);
    this.authenticationService.getOAuthToken(this.sessionResponse).subscribe((res) => {
      localStorage.setItem('authToken',res.authenticationResult.accessToken);
      localStorage.setItem('refreshToken', res.authenticationResult.refreshToken);
      localStorage.setItem('tenant', res.tenant);
      this.incidentService.getSmokeIncidents().subscribe((res) => {
        this.smokeEvents = res;
      });
    });

  }
  /* istanbul ignore next */
  openDashboard(incident) {
    this.incidentService.openDashboard(incident);
  }

  /* istanbul ignore next */
  openReport(incidentId) {
    this.incidentService.openReport(incidentId);
  }
}
