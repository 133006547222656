import {NgIf} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {TranslatePipe} from '@ngx-translate/core';
import {DamageIncidentComponent} from '../damage-incident/damage-incident.component';
import {SmokeIncidentComponent} from '../smoke-incident/smoke-incident.component';

@Component({
  selector: 'app-incident-overview',
  templateUrl: './incident-overview.component.html',
  standalone: true,
  styleUrl: './incident-overview.component.scss',
  imports: [
    SmokeIncidentComponent,
    NgIf,
    DamageIncidentComponent,
    TranslatePipe,
  ],
})

export class IncidentOverviewComponent implements OnInit {
  SMOKE_EVENT = 'com.bosch.ivs.SmokeEvent';
  DAMAGE_EVENT = 'com.bosch.ivs.damage.DamageEvent';
  toggleButtons = [
    { id: 1, name: this.SMOKE_EVENT },
    { id: 2, name: this.DAMAGE_EVENT },
  ];
  isSmokeIncident;
  isDamageIncident;

  ngOnInit(): void {
    this.isSmokeIncident = true;
  }

  /* istanbul ignore next */
  filterEvents(event: string) {
    if (event === this.SMOKE_EVENT) {
      this.isDamageIncident = false;
      this.isSmokeIncident = true;
    } else {
      this.isDamageIncident = true;
      this.isSmokeIncident = false;
    }
  }
}
