import {Component} from '@angular/core';
import {IncidentOverviewComponent} from '../incident-overview/incident-overview.component';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  standalone: true,
  imports: [
    TranslatePipe,
    IncidentOverviewComponent
  ],
  styleUrl: './admin.component.scss'
})
export class AdminComponent {

}
