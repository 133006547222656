import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {catchError, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class IncidentsService {

  constructor(public httpClient: HttpClient) {}

  /* istanbul ignore next */
  getSmokeIncidents() {
    const tenant = localStorage.getItem('tenant');
    return this.httpClient.get(`${environment.dashboardURL}/dashboard/incidents?tenant=${tenant}&incidentType=com.bosch.ivs.SmokeEvent&limit=10&skip=0`).pipe(
      catchError(this.handleError)
    );
  }

  /* istanbul ignore next */
  getDamageIncidents() {
    const tenant = localStorage.getItem('tenant');
    return this.httpClient.get(`${environment.dashboardURL}/dashboard/incidents?tenant=${tenant}&incidentType=com.bosch.ivs.damage.DamageEvent&limit=10&skip=0`).pipe(
      catchError(this.handleError)
    );
  }

  /* istanbul ignore next */
  openDashboard(incident) {
    window.open(`${environment.hostV2}/admin/incident-overview/detail/` + incident.vin + '/' + incident.messageId);
  }

  /* istanbul ignore next */
  openReport(incidentId) {
    window.open(`${environment.apiReports}/download/${incidentId}`);
  }

  // Handle HTTP errors
  /* istanbul ignore next */
  private handleError(error: string): Observable<never> {
    console.error('An error occurred:', error); // Log the error
    throw error; // You can also transform the error here and return a custom error message if needed
  }
}
