import {Component, OnInit} from '@angular/core';
import {TranslatePipe} from '@ngx-translate/core';
import {DateConversionPipe} from '../../pipes/date-conversion.pipe';
import {IncidentsService} from '../../services/incidents.service';

@Component({
  selector: 'app-damage-incident',
  templateUrl: './damage-incident.component.html',
  standalone: true,
  imports: [
    TranslatePipe,
    DateConversionPipe
  ],
  styleUrl: './damage-incident.component.scss'
})

export class DamageIncidentComponent implements OnInit{

  damageEvents;

  constructor(private readonly incidentService: IncidentsService) {
  }

  ngOnInit(): void {
    this.damageEvents = this.incidentService.getDamageIncidents().subscribe((res) => {
      this.damageEvents = res;
    });
  }

  /* istanbul ignore next */
  openDashboard(incident) {
    this.incidentService.openDashboard(incident);
  }

  /* istanbul ignore next */
  openReport(incidentId) {
    this.incidentService.openReport(incidentId);
  }
}
